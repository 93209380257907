<template>
  <div>
    <p v-if="!file" class="text-sm text-gray-500">No file uploaded.</p>
    <a v-if="file" class="text-sm text-gray-500 block">{{ file.name }}</a>
    <o-upload v-model="file" @input="fileSelected" accept=".csv">
      <o-button tag="a" class="bg-primary">
        <span>Select File</span>
      </o-button>
    </o-upload>
  </div>
</template>

<script>
import Papa from "papaparse";

export default {
  name: "CsvUpload",
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: null,
    },
    tooltip: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showUpload: false,
      imageFile: null,
      image: null,
      file: null,
      selectedFile: null,
    };
  },
  mounted() {
    if (this.value) {
      this.image = this.value;
    } else {
      this.$emit("input", null);
    }
  },
  methods: {
    fileSelected() {
      Papa.parse(this.file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          this.$emit("input", results.data);
        },
      });
    },
  },
};
</script>


<style>
.o-btn {
  @apply bg-green-400;
}
</style>

