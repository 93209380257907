<template>
  <!-- Page Content -->
  <div class="mx-10 my-2">
    <div>
      <h2 class="my-4 text-4xl font-semibold">Import Leads</h2>
      <div
        class="pb-2 flex items-center justify-between text-gray-600 border-b"
      >
        <p>Import leads from CSV file</p>
      </div>
    </div>

    <!-- Leads Content -->
    <div class="w-full">
      <div class="mt-5">
        <p class="mb-3">
          <a href="/LeadsImportTemplate.csv">Download import template</a>
        </p>
        <CsvUpload v-model="records"> </CsvUpload>
        <div v-if="records && records.length > 0">
          <p>{{ records.length }} ready to be imported.</p>
        </div>
      </div>
    </div>

    <!-- Page Content -->
    <div class="w-full mt-10 pt-5 border-t">
      <button
        @click.prevent="importRecords"
        :disabled="!records"
        class="bg-primary px-4 py-2 text-white font-semibold rounded"
      >
        Import
      </button>
      <div v-if="importing">
        {{ noImported }} of {{ records.length }} imported.
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import { leadsCollection } from "../../firebase.js";
import CsvUpload from "../../components/admin/CsvUpload";
import { mapGetters } from "vuex";

export default {
  name: "ImportLeads",
  components: {
    CsvUpload,
  },
  data: () => ({
    records: null,
    noImported: 0,
    importing: false,
  }),
  methods: {
    importRecords() {
      this.importing = true;
      this.records.forEach((record) => {
        let leadData = record;

        let booleanFields = [];
        let numberFields = [];

        booleanFields.forEach((field) => {
          if (
            leadData[field] &&
            (typeof leadData[field] === "string" ||
              leadData[field] instanceof String)
          ) {
            if (leadData[field] === "TRUE") {
              leadData[field] = true;
            } else {
              leadData[field] = false;
            }
          }
        });

        numberFields.forEach((field) => {
          if (
            leadData[field] &&
            (typeof leadData[field] === "string" ||
              leadData[field] instanceof String)
          ) {
            leadData[field] = parseInt(leadData[field], 10);
          }
        });

        let timestamp = firebase.firestore.Timestamp.now();
        if (!leadData.rating) {
          leadData.rating = "TBC";
        }
        leadData.created = timestamp;
        leadData.modified = timestamp;
        leadData.createdby = this.user.data.uid;
        leadData.modifiedby = this.user.data.uid;

        leadsCollection
          .add(leadData)
          .then(() => {
            this.noImported++;
            if (this.noImported === this.records.length) {
              this.importing = false;
              this.$notify({
                group: "global",
                type: "success",
                title: "All leads imported",
              });
            }
          })
          .catch((error) => {
            this.$notify({
              group: "global",
              type: "error",
              title: "Error importing leads",
              text: error.message,
            });
          });
      });
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  created() {},
};
</script>

<style scoped>
.o-btn {
  @apply bg-primary;
}
</style>
